/** @format */
const environment = "prod";
const env = {
	prod: {
		baseUrl: "https://api.tuhoon.com/api/v1",
		socketBaseUrl: "https://api.tuhoon.com/",
	},
	dev: {
		baseUrl: "http://localhost:5000/api/v1",
		socketBaseUrl: "http://localhost:5000/",
	},
};
export const envSettings = env[environment];
